<template>
  <div>
    <Loader v-if="startupList == null && isGetNylasData == false" />
    <div v-else class="page-container">
      <div class="curent-user-name">
        <TimeZoneCheck v-bind="{ currentUser }" /> Hi
        {{ shortenName(this.currentUser.name) }},
      </div>
      <div class="current-date">
        {{ $moment().format("dddd, Do MMM YYYY, h:mm:ss a") }}
      </div>

      <nylasPop />

      <div class="welcome-container">
        <div>
          <div class="welcome-msg">Welcome to Grupa!</div>
          <div class="create-team-msg">
           We are glad you are here. Lets get to work
          </div>
          <!-- <button
            v-if="activationLevel < 8"
            @click="handleCreateTeam"
            class="grupa-blue-btn"
          >
            Create team & Get Activated
          </button> -->
        </div>
        <div class="welcome-icon">
          <img src="/img/dashboard/illustration.svg" alt="" style="width:75px" />
        </div>
      </div>
      <div class="refer-container">
        <div class="rectangle-card">
          <div>
            <div class="current-build">Currently Building</div>
            <div class="build-text">
              Your team is not currently building any products on Grupa. Update
              your team’s profile to increase visibility.
            </div>
            <div
              v-if="activationLevel < 8"
              @click="handleCreateTeam"
              class="card-action"
            >
              Increase Your Team’s Visibility
            </div>
          </div>
          <div class="card-image">
            <img src="/img/dashboard/building-icon.svg" alt="" />
          </div>
        </div>
        <div class="rectangle-card referral-card">
          <div>
            <div class="card-text-title">Refer & Earn</div>
            <div class="build-text">
              Refer your friends as Founder or a FAANG Expert and get up to
              $1,250 for every successful referral.
            </div>
            <div class="card-action" @click="goToReferral">Refer Now</div>
          </div>
          <div class="card-image">
            <img src="/img/dashboard/referral-icon-db.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="hr-line"></div>
      <div class="product-request-container">
        <div class="product-request-text">Priority Requests</div>
        <!-- <div class="text-grey">
          Explore latest product requests by founders in the Grupa network
        </div> -->
        <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
          <div class="startup-list-container" v-if="priorityStartups">
            <StartUpCard
              v-for="startup in priorityStartups"
              :key="startup.id"
              :role="{ teamRolesArrData }"
              v-bind="{ startup }"
            />
          </div>
        </VuePerfectScrollbar>
      </div>
      <div class="hr-line"></div>
      <div class="product-request-container">
        <div class="product-request-text">Coming soon</div>
        <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
          <div class="startup-list-container" v-if="waitlistStartups">
            <StartUpCard
              v-for="startup in waitlistStartups"
              :key="startup.id"
              data="coming_soon"
              v-bind="{ startup }"
            />
          </div>
        </VuePerfectScrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Loader from "@/components/general/centerLoader";
import shortName from "@/mixins/shortName";
import appMixin from "@/mixins/appMixin";
import { mapActions, mapState } from "vuex";
import StartUpCard from "./partials/startUpCard.vue";
import { getAllStartUps, UpdateTimeZone } from "@/api";
import TimeZoneCheck from "@/views/TimeZoneCheck/index";
import { getRoles } from "@/api/client";
import nylasPop from "./../../../../ClientDashboard/workspace/newProject/dashboard/nylasPop.vue"

import { getGrupaNylasRecord, connectGrupaToNylas } from "@/api/nylasApi";

import Axios from "axios";

export default {
  mixins: [shortName, appMixin],
  props: {},
  components: {
    StartUpCard,
    VuePerfectScrollbar,
    TimeZoneCheck,
    Loader,
    nylasPop,
  },
  data: () => ({
    isLoadingRoles: true,
    teamRolesArrData: [],
    isGetStartUpList: false,
    isGetNylasData: false,
    devNylasRecord: [],
    isHideBar: true,

    isReloadingContent: false,
    settings: {
      maxScrollbarLength: 60,
    },
  }),
  async mounted() {
    this.$nextTick(async () => {
      if (!this.isGetStartUpList) {
        // alert("fetchStartUpList");
        this.fetchStartUpList();
        this.isGetStartUpList = true;
      }

      // this.isGetNylasData = true;

      if (!this.isGetNylasData) {
        this.getGrupaNylasRecordFunction();
        this.isGetNylasData = true;
      }
      $(".step2, .step3, .nylasLoader, .nylasAlert, .nylasLoader2").fadeOut();

      let InnterVal = setInterval(() => {
        $(".nylasLoader2").fadeToggle(3000);
      }, 1500);

      //alert(this.showNylasBar())

      let nylas_bar = setTimeout(() => {
        $(".nylas-warning-container").fadeIn(1000);
        clearTimeout(nylas_bar);
      }, 3500);

      $(".isSetupProfileClicked").on("click", function () {
        $(".step2, .step3").fadeOut(() => {
          $(".nylasLoader, .nylasAlert, .nylasLoader2").fadeOut();

          $(".step1").fadeIn();
        });
      });

      $(".nylasBarButton_").on("click", async function () {
        const user = JSON.parse(localStorage.getItem("grupa_user"));
        let baseURL = process.env.VUE_APP_BACKEND_API;
        $(".nylasLoader").fadeIn();

        let button = $(this);

        await $.ajax({
          url: `${baseURL}/connect/nylas/nylas_authentication_curl/`,
          type: "POST",
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", `Bearer ${user.token}`);
            // xhr.setRequestHeader("contentType", "application/json");
            button.hide();
            //console.log(user.email);
          },

          data: {
            email: user.email,
            //name: "eze okechukwu",
          },
          // data: jQuery.param({ email: user.email }),
          success: function (data) {
            // alert(data);
            $(".nylasLoader").fadeOut(() => {
              // button.hide();
              // $("#nylasAuth").attr("src", data);
            });

            // console.log(data);

            $(".step1").fadeOut(() => {
              $(".step2").fadeIn();
              $(".nylasLoader2").fadeIn();
            });
            //return data;
          },
          error: function (error) {
            console.log(error);

            button.show();
            $(".nylasLoader").fadeOut();
            $(".nylasLoader2").fadeOut();

            return error;
          },
        });
      });
    });
  },
  created() {
    getRoles()
      .then((data) => {
        //console.log(data.data.data)
        let response = data.data.data;
        this.isLoadingRoles = false;

        for (var i = 0; i < response.length; i++) {
          this.teamRolesArrData.push(
            response[i].name
            // value: response[i].id,
            //id: response[i].id,
            //img: response[i].img,
          );
        }

        // console.log(this.teamRolesArrData);
        //console.log(this.projectIndustries)
      })
      .catch((error) => {
        this.isLoadingRoles = false;
        console.log(error);
      });
  },
  methods: {
    async ConfirmTimeZone() {
      const payload = {
        timezone: this.browserTimezone,
      };
      UpdateTimeZone(payload)
        .then((response) => {
          // alert('yes')
         // console.log("UpdateTimeZone : ", response);
        })
        .catch((error) => {
          // alert('ERROR')
          console.log(error);
        });
    },
    ...mapActions("devDashboard", ["setStartupList", "setStartupIndex"]),

    validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },

    handleGetNylasUniqueURL() {
      const user = JSON.parse(localStorage.getItem("grupa_user"));

      let payload = {
        email: user.email,
      };

      let button = $(".nylasBarButton");

      button.fadeOut(0);

      $(".nylasLoader").fadeIn(() => {
        connectGrupaToNylas(payload)
          .then((data) => {
            var nylas_window = window.open(
              data.data,
              "",
              "toolbar=no,status=no,menubar=no,location=center,scrollbars=no,resizable=no,height=600,width=657"
            );

            $("#showNylasBar").modal("hide");

            // console.log(nylas_window);

            const timer = setInterval(() => {
              let url = nylas_window.location.href;
              console.log(url);
              if (nylas_window.closed) {
                clearInterval(timer);
                //
                clearTimeout(InnterVal);
                //

                //console.log("closed");
              }
            }, 1000);

            //logout-link
            if (this.validURL(data.data)) {
              button.show();
              $(".nylasLoader").fadeOut(() => {
                // button.hide();
                // $("#nylasAuth").attr("src", data.data);

                $(".step1").fadeOut(() => {
                  $(".step2").fadeIn();
                });
              });
            } else {
              button.show();
              $(".nylasLoader").fadeOut();
              $(".nylasAlert").fadeIn();
            }
            //console.log(this.validURL(data.data));
            //console.log(data.data);
          })
          .catch((error) => {
            console.log(error);

            button.show();
            $(".nylasLoader").fadeOut();
            $(".nylasAlert").fadeIn();
          });
      });

      $(".nylasAlert").fadeOut();
    },

    showNylasBar() {
      let state = this.devNylasRecord.hasOwnProperty("user")
        ? Object.keys(this.devNylasRecord.user).length <= 0
          ? true
          : false
        : true;
      this.isHideBar = false;
      return state;
    },

    async getGrupaNylasRecordFunction() {
      if (!this.isGetNylasData) {
        let payload = {
          email: this.currentUser.email, //"okechukwu0127@gmail.com", //
        };
        this.isReloadingContent = true;
        await getGrupaNylasRecord(payload)
          .then((res) => {
            this.devNylasRecord = res.data.data;
            //console.log(JSON.stringify(res.data.data));
            this.isGetNylasData = true;

            this.isReloadingContent = false;
          })
          .catch((error) => {
            this.isReloadingContent = true;
            console.log("ERROR getGrupaNylasRecord: ", error);
          });
      }
    },
    fetchStartUpList() {
      
      if (!this.isGetStartUpList) {
        getAllStartUps()
          .then((res) => {
            this.isGetStartUpList = true;
            if (res.data.status == 1) {
              const startups = res.data.data;
              if (startups && startups.length && this.currentTeamInterests) {
                startups.sort((s1, s2) => {
                  const s1CommonInterests = _.intersection(
                    this.parseToJson(s1.industry),
                    this.currentTeamInterests
                  );
                  const s2CommonInterests = _.intersection(
                    this.parseToJson(s2.industry),
                    this.currentTeamInterests
                  );
                  return s2CommonInterests.length - s1CommonInterests.length;
                });
              }
             // alert('startup')
              //this.setStartupList([]);
             this.setStartupList(startups);

            }
          })
          .catch((err) => {
            this.isGetStartUpList = true;
            console.log("ERROR getAllStartUps: ", err);
          });
      }
    },
    handleCreateTeam() {
      this.$router.push({ name: "team" });
    },
    goToReferral() {
      this.$router.push({ name: "dev-referral" });
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo", "startupList"]),
    currentTeamInterests() {
      return this.parseToJson(this.teamInfo.team.interests);
    },
    currentUser() {
      return this.teamInfo.user;
    },
    activationLevel() {
      return this.teamInfo.team.activation_level;
    },
    waitlistStartups() {
      return this.startupList.filter((s) => !s.is_priority);
    },
    priorityStartups() {
      return this.startupList.filter((s) => s.is_priority);
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;

  /* #E4E5E7 */
}

.nylasLoader,
.nylasLoader2 {
  margin-top: 50px;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nylasBarTextSub {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* or 20px */

  color: #979da5;
  padding-top: 17px;
  padding-left: 30px;
  padding-right: 30px;
}

.nylasBarButton {
  margin: auto;
  margin-top: 60px;
  margin-bottom: 20px;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  cursor: pointer;
  width: 372px;
  height: 45px;

  /* primary/0 */

  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(27, 30, 34, 0.07),
    1px 2px 4px rgba(27, 30, 34, 0.15);
  border-radius: 3px;
}

.nylasBarText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* or 20px */

  color: #1b1e22;
  padding-top: 32px;
  padding-left: 30px;
  padding-right: 30px;
}

.nylas-warning-container {
  background: radial-gradient(56.56% 50% at 50% 50%, #23272c 0%, #15171a 100%);
  border-radius: 4px;
  padding: 24px 62px 24px;
  display: flex;
  margin-top: 34px;
  justify-content: space-between;
  text-align: left;
}

.page-container {
  padding: 16px 32px 32px;
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
}
.curent-user-name {
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #1b1e22;
  text-align: left;
}
.current-date {
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  text-align: left;
}
.welcome-container {
  background: radial-gradient(56.56% 50% at 50% 50%, #23272c 0%, #15171a 100%);
  border-radius: 4px;
  padding: 24px 62px 24px;
  display: flex;
  margin-top: 34px;
  justify-content: space-between;
  text-align: left;
}

.welcome-msg {
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #fdfdfd;
}
.create-team-msg {
  width: 397px;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  margin-top: 8px;
  color: #979da5;
}
.welcome-container .grupa-blue-btn {
  margin-top: 34px;
}
.refer-container {
  display: flex;
  margin-top: 16px;
  gap: 16px;
}
.rectangle-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  display: flex;
  text-align: left;
  justify-content: space-between;
  width: 50%;
}
.card-text-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #1b1e22;
}
.build-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  max-width: 266px;
  margin-top: 12px;
}
.card-action {
  font-size: 12px;
  line-height: 140%;
  color: #0781f2;
  margin-top: 24px;
  cursor: pointer;
}
.referral-card {
  background: #fbefee;
}
.referral-card .card-text-title {
  color: #d45b54;
}
.referral-card .build-text {
  color: #d45b54;
}
.hr-line {
  margin-top: 32px;
  border-top: 1px solid #e4e5e7;
}
.product-request-container {
  margin-top: 32px;
  text-align: left;
}

.product-request-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #1b1e22;
}
.text-grey {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  margin-top: 4px;
  color: #979da5;
}
.startup-list-container {
  display: flex;
  margin-top: 32px;
  gap: 16px;
}
@media (max-width: 768px) {
  .create-team-msg {
    width: unset;
  }
  .welcome-icon,
  .card-image {
    display: none;
  }
  .rectangle-card {
    width: 100%;
  }
  .refer-container {
    flex-direction: column;
  }
}
</style>
