var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.startupList == null && _vm.isGetNylasData == false
        ? _c("Loader")
        : _c(
            "div",
            { staticClass: "page-container" },
            [
              _c(
                "div",
                { staticClass: "curent-user-name" },
                [
                  _c(
                    "TimeZoneCheck",
                    _vm._b(
                      {},
                      "TimeZoneCheck",
                      { currentUser: _vm.currentUser },
                      false
                    )
                  ),
                  _vm._v(
                    " Hi\n      " +
                      _vm._s(_vm.shortenName(this.currentUser.name)) +
                      ",\n    "
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "current-date" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$moment().format("dddd, Do MMM YYYY, h:mm:ss a")
                    ) +
                    "\n    "
                ),
              ]),
              _c("nylasPop"),
              _c("div", { staticClass: "welcome-container" }, [
                _c("div", [
                  _c("div", { staticClass: "welcome-msg" }, [
                    _vm._v("Welcome to Grupa!"),
                  ]),
                  _c("div", { staticClass: "create-team-msg" }, [
                    _vm._v(
                      "\n         We are glad you are here. Lets get to work\n        "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "welcome-icon" }, [
                  _c("img", {
                    staticStyle: { width: "75px" },
                    attrs: { src: "/img/dashboard/illustration.svg", alt: "" },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "refer-container" }, [
                _c("div", { staticClass: "rectangle-card" }, [
                  _c("div", [
                    _c("div", { staticClass: "current-build" }, [
                      _vm._v("Currently Building"),
                    ]),
                    _c("div", { staticClass: "build-text" }, [
                      _vm._v(
                        "\n            Your team is not currently building any products on Grupa. Update\n            your team’s profile to increase visibility.\n          "
                      ),
                    ]),
                    _vm.activationLevel < 8
                      ? _c(
                          "div",
                          {
                            staticClass: "card-action",
                            on: { click: _vm.handleCreateTeam },
                          },
                          [
                            _vm._v(
                              "\n            Increase Your Team’s Visibility\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "card-image" }, [
                    _c("img", {
                      attrs: {
                        src: "/img/dashboard/building-icon.svg",
                        alt: "",
                      },
                    }),
                  ]),
                ]),
                _c("div", { staticClass: "rectangle-card referral-card" }, [
                  _c("div", [
                    _c("div", { staticClass: "card-text-title" }, [
                      _vm._v("Refer & Earn"),
                    ]),
                    _c("div", { staticClass: "build-text" }, [
                      _vm._v(
                        "\n            Refer your friends as Founder or a FAANG Expert and get up to\n            $1,250 for every successful referral.\n          "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "card-action",
                        on: { click: _vm.goToReferral },
                      },
                      [_vm._v("Refer Now")]
                    ),
                  ]),
                  _c("div", { staticClass: "card-image" }, [
                    _c("img", {
                      attrs: {
                        src: "/img/dashboard/referral-icon-db.svg",
                        alt: "",
                      },
                    }),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "hr-line" }),
              _c(
                "div",
                { staticClass: "product-request-container" },
                [
                  _c("div", { staticClass: "product-request-text" }, [
                    _vm._v("Priority Requests"),
                  ]),
                  _c(
                    "VuePerfectScrollbar",
                    {
                      staticClass: "perfect-scroll-area",
                      attrs: { settings: _vm.settings },
                    },
                    [
                      _vm.priorityStartups
                        ? _c(
                            "div",
                            { staticClass: "startup-list-container" },
                            _vm._l(_vm.priorityStartups, function (startup) {
                              return _c(
                                "StartUpCard",
                                _vm._b(
                                  {
                                    key: startup.id,
                                    attrs: {
                                      role: {
                                        teamRolesArrData: _vm.teamRolesArrData,
                                      },
                                    },
                                  },
                                  "StartUpCard",
                                  { startup: startup },
                                  false
                                )
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "hr-line" }),
              _c(
                "div",
                { staticClass: "product-request-container" },
                [
                  _c("div", { staticClass: "product-request-text" }, [
                    _vm._v("Coming soon"),
                  ]),
                  _c(
                    "VuePerfectScrollbar",
                    {
                      staticClass: "perfect-scroll-area",
                      attrs: { settings: _vm.settings },
                    },
                    [
                      _vm.waitlistStartups
                        ? _c(
                            "div",
                            { staticClass: "startup-list-container" },
                            _vm._l(_vm.waitlistStartups, function (startup) {
                              return _c(
                                "StartUpCard",
                                _vm._b(
                                  {
                                    key: startup.id,
                                    attrs: { data: "coming_soon" },
                                  },
                                  "StartUpCard",
                                  { startup: startup },
                                  false
                                )
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }