import _ from "lodash";
const truncate = {
  data() {
    return {};
  },
  methods: {
    truncate(str, length) {
      return _.truncate(str, {
        length,
        separator: " ",
      });
    },
  },
};

export default truncate;
