<template>
  <div v-if="team">
    <Toolbar stageTitle="Overview" />
    <div>
      <!-- <Overview
        v-if="currentUser.activation_level >= 2 && team.activation_level == 8"
      /> -->
      <StartUpList />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Toolbar from "@/components/toolbar/generalToolBar";
// import Overview from "./dashboard";
import StartUpList from "./vetting/startUps";
export default {
  components: {
    StartUpList,
    Toolbar,
  },
  data() {
    return {
      user: {},
    };
  },
  methods: {},
  computed: {
    ...mapState("devDashboard", ["teamInfo"]),
    ...mapState("miniProject", ["teamMiniProject"]),
    currentUser() {
      return this.teamInfo.user;
    },
    team() {
      return this.teamInfo.team;
    },
  },
};
</script>

<style scoped>
</style>


