var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.team
    ? _c(
        "div",
        [
          _c("Toolbar", { attrs: { stageTitle: "Overview" } }),
          _c("div", [_c("StartUpList")], 1),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }