var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "startup-card", on: { click: _vm.handleViewStartup } },
    [
      _c("div", { staticClass: "startup-name" }, [
        _c(
          "span",
          {
            class:
              "" +
              (_vm.data === "coming_soon" ? "request-title-coming-soon" : ""),
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.startup.name ? _vm.startup.name : "")
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "startup-industry" },
        _vm._l(_vm.parseToJson(_vm.startup.industry), function (industry, i) {
          return _c("div", { key: i, staticClass: "industry-chip-div" }, [
            _c("div", { staticClass: "industry-text" }, [
              _c(
                "span",
                {
                  class:
                    "" +
                    (_vm.data === "coming_soon"
                      ? "request-title-coming-soon"
                      : ""),
                },
                [_vm._v("\n          " + _vm._s(industry))]
              ),
            ]),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "startup-brief" }, [
        _c(
          "span",
          {
            class:
              "" +
              (_vm.data === "coming_soon" ? "request-title-coming-soon" : ""),
          },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.truncate(_vm.startup.description, 130))
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "startup-info" }, [
        _c("div", { staticClass: "startup-request" }, [
          _c("div", { staticClass: "request-title" }, [_vm._v("looking for")]),
          _vm.project
            ? _c(
                "div",
                { staticClass: "available-role-div" },
                _vm._l(
                  _vm.parseTeamRoles(_vm.project.looking_for),
                  function (l, index) {
                    return _c("div", { key: index, staticClass: "chip-box" }, [
                      _c(
                        "span",
                        {
                          class:
                            "" +
                            (_vm.data === "coming_soon"
                              ? "request-title-coming-soon"
                              : ""),
                        },
                        [_vm._v("\n            " + _vm._s(l))]
                      ),
                    ])
                  }
                ),
                0
              )
            : _vm._e(),
        ]),
        _vm.project && _vm.compensation
          ? _c("div", { staticClass: "startup-offer" }, [
              _c("div", { staticClass: "offer-title" }, [
                _vm._v("compensates in"),
              ]),
              _c("div", [
                _c("div", { staticClass: "offer-list" }, [
                  _c("img", {
                    attrs: { src: "/img/dashboard/money-icon.svg", alt: "" },
                  }),
                  _c(
                    "span",
                    {
                      class:
                        "" +
                        (_vm.data === "coming_soon"
                          ? "request-title-coming-soon"
                          : ""),
                    },
                    [_vm._v("\n            " + _vm._s(_vm.compensation))]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "startup-investors" }, [
        _c("div", { staticClass: "investor-title" }, [
          _c(
            "span",
            {
              class:
                "" +
                (_vm.data === "coming_soon" ? "request-title-coming-soon" : ""),
            },
            [_vm._v("\n        backed by")]
          ),
        ]),
        _c(
          "div",
          { staticClass: "investor-list" },
          _vm._l(
            _vm.parseToJson(_vm.startup.backed_by),
            function (investor, index) {
              return _c("div", { key: index }, [
                investor == "Y Combinator" ||
                investor == "500 Startups" ||
                investor == "Pear VC"
                  ? _c("img", {
                      attrs: {
                        src: "/img/dashboard/investors/" + investor + ".svg",
                        alt: investor,
                      },
                    })
                  : _c("span", [
                      _c(
                        "span",
                        {
                          class:
                            "" +
                            (_vm.data === "coming_soon"
                              ? "request-title-coming-soon"
                              : ""),
                        },
                        [_vm._v("\n            " + _vm._s(investor))]
                      ),
                    ]),
              ])
            }
          ),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }