<template>
  <!-- @click="$emit('click')" -->
  <div class="startup-card" @click="handleViewStartup">
    <div class="startup-name">
      <span
        :class="`${data === 'coming_soon' ? 'request-title-coming-soon' : ''}`"
      >
        {{ startup.name ? startup.name : "" }}</span
      >
    </div>
    <div class="startup-industry">
      <div
        class="industry-chip-div"
        v-for="(industry, i) in parseToJson(startup.industry)"
        :key="i"
      >
        <!-- <div class="industry-icon">
          <img src="/img/dashboard/money-icon.svg" alt="" />
        </div> -->
        <div class="industry-text">
          <span
            :class="`${
              data === 'coming_soon' ? 'request-title-coming-soon' : ''
            }`"
          >
            {{ industry }}</span
          >
        </div>
      </div>
    </div>
    <div class="startup-brief">
      <span
        :class="`${data === 'coming_soon' ? 'request-title-coming-soon' : ''}`"
      >
        {{ truncate(startup.description, 130) }}</span
      >
    </div>
    <div class="startup-info">
      <div class="startup-request">
        <div class="request-title">looking for</div>
        <div class="available-role-div" v-if="project">
          <div
            v-for="(l, index) in parseTeamRoles(project.looking_for)"
            :key="index"
            class="chip-box"
          >
            <span
              :class="`${
                data === 'coming_soon' ? 'request-title-coming-soon' : ''
              }`"
            >
              {{ l }}</span
            >
          </div>
        </div>
      </div>
      <div class="startup-offer" v-if="project && compensation">
        <div class="offer-title">compensates in</div>
        <div>
          <div class="offer-list">
            <img src="/img/dashboard/money-icon.svg" alt="" />
            <span
              :class="`${
                data === 'coming_soon' ? 'request-title-coming-soon' : ''
              }`"
            >
              {{ compensation }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="startup-investors">
      <div class="investor-title">
        <span
          :class="`${
            data === 'coming_soon' ? 'request-title-coming-soon' : ''
          }`"
        >
          backed by</span
        >
      </div>
      <div class="investor-list">
        <div
          v-for="(investor, index) in parseToJson(startup.backed_by)"
          :key="index"
        >
          <img
            v-if="
              investor == 'Y Combinator' ||
              investor == '500 Startups' ||
              investor == 'Pear VC'
            "
            :src="`/img/dashboard/investors/${investor}.svg`"
            :alt="investor"
          />
          <span v-else>
            <span
              :class="`${
                data === 'coming_soon' ? 'request-title-coming-soon' : ''
              }`"
            >
              {{ investor }}</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import truncate from "@/mixins/truncate";
import appMixin from "@/mixins/appMixin";

export default {
  mixins: [appMixin, truncate],
  props: {
    startup: {
      required: true,
      type: Object,
    },
    role: {
      type: Array,
    },
    data: {
      type: String,
    },
  },
  components: {},

  data: () => ({}),

  created() {},
  methods: {
    ...mapActions("devDashboard", ["setStartupIndex"]),

    handleViewStartup() {
      const startups = this.startupList;
      const startupIndex = startups.findIndex((s) => s.id == this.startup.id);
      this.setStartupIndex(startupIndex);
      if (this.data != "coming_soon") {
        this.$router.push({
          name: "view_startup",
          params: { id: this.startup.id },
        });
      }
    },
    parseTeamRoles(teamRoles) {
      if (!teamRoles) {
        return "";
      }
      const teamRolesObj = this.hasOwnProperty("role")
        ? this.role.hasOwnProperty("teamRolesArrData")
          ? this.role.teamRolesArrData
          : []
        : [];

      try {
        const r = JSON.parse(teamRoles);
        if (Array.isArray(r)) {
          return r.map((roleId) => teamRolesObj[roleId]);
        }
        return [r];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
  },
  computed: {
    ...mapState("devDashboard", ["teamInfo", "startupList"]),
    team() {
      return this.teamInfo.team;
    },
    project() {
      if (!this.startup.projects || !this.startup.projects.length) {
        return null;
      }

      return this.startup.projects[this.startup.projects.length - 1];
    },
    compensation() {
      const comp = parseInt(this.project.compensation_id);
      if (!comp) {
        return "";
      }
      let text = "";
      try {
        const compensationModels = {
          1: "Cash Only",
          2: "A mix of Cash & Equity",
          3: "Equity only",
          4: `I’m not sure yet`,
        };
        text = compensationModels[comp];
      } catch (error) {
        console.error(error);
      }
      return text;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}

.startup-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  cursor: pointer;
}
.startup-name {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #1b1e22;
}
.startup-industry {
  margin-top: 24px;
  width: 344px;
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
}
.industry-chip-div {
  padding: 8px 16px;
  /* background: rgba(84, 189, 212, 0.1); */
  background: rgba(7, 129, 242, 0.1);
  border-radius: 50px;
  display: flex;
}
.industry-text {
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #0781f2;
  margin: 2px 0px 0px 4px;
}
.startup-brief {
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
  width: 344px;
  margin-top: 24px;
  height: 70px;
}
.startup-info {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.offer-title,
.request-title {
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #979da5;
  margin-bottom: 10px;
}

.request-title-coming-soon {
  color: #979da5;
}
.offer-list {
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
  display: flex;
}
.available-role-div {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
}
.chip-box {
  background: rgba(122, 111, 246, 0.1);
  border-radius: 50px;
  padding: 8px 16px;
  font-size: 10px;
  line-height: 12px;
  color: #7a6ff6;
}
.investor-title {
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  margin-top: 24px;
}
.investor-list {
  margin-top: 8px;
  display: flex;
  gap: 16px;
}
</style>
